import * as React from 'react';
import styled from '@emotion/styled/macro';
import { DropzoneArea } from 'material-ui-dropzone'
import without from 'lodash/without';

import {Field} from "formik";
import Attach from "./Attach";

function Attachments({ className, field: { name, value }, form: { setFieldValue } }) {
  const [forUploading, setUploading] = React.useState(() => []);
  const [forDeleting, setDeleting] = React.useState(() => []);
  const onDelete = React.useCallback((id) => setDeleting([...forDeleting, id]), [setDeleting, forDeleting]);
  const onRevert = React.useCallback((id) => setDeleting(without(forDeleting, id)), [setDeleting, forDeleting]);

  React.useEffect(() => {
    setFieldValue(`new_${name}`, forUploading);
    setFieldValue(`removing_${name}`, forDeleting);
  }, [forDeleting, forUploading, name, setFieldValue]);

  return (
    <div className={className}>
      {value.length || forUploading.length ? (
        <>
          <ul>
            {value.map(attach => (
              <Attach
                key={attach.id}
                onDelete={onDelete}
                onRevert={onRevert}
                deleted={forDeleting.includes(attach.id)}
              >{attach}</Attach>
            ))}
          </ul>
        </>
      ) : null}
      <DropzoneArea
        onChange={setUploading}
        showAlerts={false}
        dropzoneText="Drag and drop a file here or click"
        maxFileSize={10*1024*1024}
      />
    </div>
  );
}

export default styled(({ name, ...props }) => <Field name={name} component={Attachments} {...props} />)`
  display: flex;
  width: 100%;

  @media(max-width: 1000px) {
    flex-direction: column;
  }

  @media print {
    display: none;
  }

  & > div, & > ul {
    flex: 1;
  }

  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      margin: 8px;
    }
  }
`;
