import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import MuiInput from "@material-ui/core/Input/Input";
import { Field } from 'formik';

class Input extends React.Component {
  static propTypes = {
    field: PropTypes.shape().isRequired,
    form: PropTypes.shape().isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
  };

  static defaultProps = {
    required: false,
  };

  render() {
    const {
      field: { name, value, ...field },
      form: { errors, touched },
      required,
      label,
      helperText,
      className,
      ...props
    } = this.props;
    const hasError = errors[name] && touched[name];
    const errorMessage = hasError ? errors[name] : null;
    return (
      <FormControl margin="dense" required={required} fullWidth error={hasError} className={className}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <MuiInput id={name} name={name} value={value === null ? '' : value} {...field} {...props} />
        <FormHelperText>{errorMessage || helperText || ''}</FormHelperText>
      </FormControl>
    );
  }
}

export default styled(({ name, ...props }) => <Field name={name} component={Input} {...props} />)``;
