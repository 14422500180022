import styled from '@emotion/styled/macro';
import Month from './Month';
import Button from './Button';

export default styled.div`
  flex-direction: row;
  
  & > ${Button} {
    width: 64px;
  
    &:first-of-type {
      border-right-width: 1px;
      
      & > svg {
        transform: rotate(90deg);
      }
    }
    
    &:last-of-type {
      border-left-width: 1px;
      
      & > svg {
        transform: rotate(-90deg);
      }
    }
  }
  
  & > ${Month} {
    width: calc(100% / 3);
    
    & > div {
      height: 198px;
    }
  }
`;
