import RecordList from '../components/RecordList';

import PATH from './CalendarAndPlaces.path';

const CalendarAndPlaces = {
  PATH,
  element: <RecordList />,
};

export default CalendarAndPlaces;
