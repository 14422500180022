import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { gql } from 'graphql.macro';
import { useMediaQuery } from 'react-responsive'

import Dropdown from "./Dropdown";
import Tabs from "./Tabs";
import { MOBILE } from "../media-query";
import {Toolbar} from "@material-ui/core";

const styles = {
  root: {
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

function BareAppBar(props) {
  const { classes, user: { places } } = props;
  const isMobile = useMediaQuery(MOBILE);
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {isMobile ? <Dropdown places={places} /> : <Tabs places={places} />}
        </Toolbar>
      </AppBar>
    </div>
  );
}

BareAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

BareAppBar.fragments = {
  places: gql`
    fragment Places on Viewer {
      places {
        id
        name
      }
    }
  `,
};

export default withStyles(styles)(BareAppBar);

