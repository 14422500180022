import * as React from 'react';
import styled from '@emotion/styled/macro';
import TableCell from '@material-ui/core/TableCell';

const regexp = /^\s*(\d{1,2}:\d{2})\D+(\d{1,2}:\d{2})\s*$/;

export default React.memo(styled(({ time, className }) => {
  const match = time.match(regexp);
  if (match === null) {
    return <TableCell className={className}>{time}</TableCell>;
  }

  const [, from, to] = match;
  return (
    <TableCell className={className}>
      <div>{from}</div>
      <div>{to}</div>
    </TableCell>
  );
})`
  width: 50px;
  
  & > div + div {
    font-size: 10px;
    &:before {
      content: '–';
    }
  }
`);
