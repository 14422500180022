import { gql } from "graphql.macro";

export default gql`
  fragment Record on Record {
    id
    place {
      id
      name
    }
    manager {
      name
    }
    date
    time
    excursion
    startPoi
    expectedPrice
    pax
    ref
    note
    implementer
    status
  }
`;
