import * as React from 'react';
import Paper from "@material-ui/core/Paper/Paper";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import {format, parseISO} from "date-fns";
import classNames from "classnames";
import enLocale from "date-fns/locale/en-GB";
import styled from '@emotion/styled/macro';

const Content = styled.div`
  padding: 1em;
`;

const Action = styled.div`
  width: 21px;
  height: 21px;
  padding: 0;
  opacity: 0.25;
  transform: rotateX(180deg);

  & > svg {
    width: 17px;
    height: 17px;
    padding: 2px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;

  & > span {
    padding: 0.25em 1em;
  }

  &:hover > ${Action} {
    opacity: 1;
  }
`;

function formatDate(date) {
  if (!date) return null;
  return format(parseISO(date), 'PPpp', { locale: enLocale });
}

class HistoryEntry extends React.PureComponent {
  state = {
    collapsed: null
  };

  toggle = () => {
    const { collapsed: initState } = this.props;
    this.setState(({ collapsed }) => ({ collapsed: collapsed === null ? !initState : !collapsed }));
  };

  render() {
    const { date, author, children, className } = this.props;
    const { collapsed: initState } = this.props;
    const { collapsed } = this.state;
    return (
      <Paper className={classNames(className, { collapsed: collapsed === null ? initState : collapsed })}>
        <Title onClick={this.toggle}>
          <span>{formatDate(date)} by <b title={author.phone}>{author.name}{author.phone ? <>{" | "}<nobr>{author.phone}</nobr></> : null}</b></span>
          <Action><ArrowDownward /></Action>
        </Title>
        <Content>
          {children}
        </Content>
      </Paper>
    )
  }
}

export default styled(HistoryEntry)`
  margin-bottom: 1em;

  &.collapsed ${Action} {
    transform: rotateX(0deg);
  }

  &.collapsed > ${Title} {
    box-shadow: none;
    border: none;
  }

  &.collapsed > ${Content} {
    display: none;
  }
`;
