import * as React from "react";
import { Logos } from "./Logos";

const SVGBalder = React.forwardRef(({ name, position, phone = "", email, photo }, ref) => {
    return (
      <div ref={ref} className="svg-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="600" height="350" viewBox="0 0 600 350">
          <rect width="100%" height="100%" fill="#313131"/>
          <path transform="translate(100 30) scale(0.75)"
                d="m 0,0 h 8.5 c 6.5,0 10.2,2.7 10.2,8.1 0,2.8 -1.4,5 -3.9,6.6 3.3,2.1 4.6,3.9 4.6,7.8 0,6.3 -4.2,9.1 -10.6,9.1 H 0 Z m 6,12.5 h 2.1 c 2.7,0 4.5,-1.6 4.5,-3.7 C 12.6,6.5 11.2,5 8.4,5 H 6 Z m 0,14.1 h 2.5 c 3.2,0 4.8,-1.8 4.8,-4.6 0,-3 -2.1,-4.5 -5.3,-4.5 H 6 Z m 15.5,5 L 29.3,0 h 7.8 l 7.6,31.6 H 38.9 L 37.4,25 H 29 l -1.7,6.7 h -5.8 z m 8.6,-12 h 6 L 33.2,6.8 H 33.1 Z M 48,31.6 V 0 h 6 v 26.3 h 9.7 v 5.3 z M 67.4,0 h 8.4 c 6.9,0 10.8,3 11,10.9 v 9.7 c -0.3,8 -4.1,10.9 -11,10.9 h -8.4 z m 6,26.3 h 2.1 c 3.3,0 5.3,-1.6 5.3,-6.3 v -8.3 c 0,-4.5 -1.5,-6.3 -5.3,-6.3 h -2.1 z m 18.4,5.3 V 0 h 16.6 V 5.3 H 97.8 v 7.3 h 8 V 18 h -8 v 8.3 h 10.9 v 5.3 z m 36,0 -5.3,-12.9 h -2.9 v 12.9 h -6 V 0 h 8.8 c 7.3,0 10.7,3.5 10.7,9.5 0,3.9 -1.4,6.8 -4.8,8.1 l 6,14.1 h -6.5 z m -8.1,-17.8 h 3.1 c 2.7,0 4.3,-1.3 4.3,-4.4 0,-3.1 -1.6,-4.4 -4.3,-4.4 h -3.1 z M 25.5,45.1 H 0 V 46 h 25.5 z m 82.9,0 V 46 h 25.5 V 45.1 Z M 43.7,51.3 V 39.4 h 6.1 v 1.8 h -4.1 v 3.1 h 3.1 V 46 h -3.1 v 3.5 H 50 v 1.8 z m 12.6,0 -1.8,-4.3 v 0 l -1.9,4.3 h -2.1 l 2.8,-6.2 -2.6,-5.8 h 2.2 l 1.6,3.8 v 0 l 1.7,-3.8 h 2.1 l -2.6,5.7 2.8,6.2 h -2.2 z m 3.2,0 V 39.4 h 3.1 c 2.6,0 4,1.1 4,3.5 0,2.4 -1.4,3.6 -4,3.6 h -1.1 v 4.7 h -2 z m 3.1,-6.4 c 1,0 1.9,-0.4 1.9,-1.9 0,-1.3 -0.7,-1.8 -1.8,-1.8 h -1.2 l 0.0139,3.705666 z m 5.1,6.4 V 39.4 h 6.1 v 1.8 h -4.1 v 3.1 h 3.1 V 46 h -3.1 v 3.5 H 74 v 1.8 z m 12.9,0 -1.7,-4.9 h -1.5 v 4.9 h -2 V 39.4 h 3.2 c 2.6,0 3.8,1 3.8,3.3 0,1.5 -0.6,2.4 -1.7,2.9 l 2.1,5.7 z m -2.1,-6.5 c 0.9,0 1.8,-0.3 1.8,-1.8 0,-1.7 -0.9,-1.8 -1.8,-1.8 h -1.1 v 3.6 z m 7.3,6.5 V 41.2 h -2.5 v -1.8 h 6.9 v 1.8 h -2.5 v 10.1 z"
                id="path10"
                fill="white"/>

          <line x1="20" y1="90" x2="280" y2="90" stroke="white" strokeWidth="2"/>
          <text x="20" y="110" fontFamily="Helvetica" fontSize="13" fill="white">Itämerenkatu 8,</text>
          <text x="20" y="125" fontFamily="Helvetica" fontSize="13" fill="white">00180 Helsinki, Finland</text>
          <text x="20" y="140" fontFamily="Helvetica" fontSize="13" fill="white">Phone: <a href={`tel:${phone.replaceAll(' ', '')}`} fill="white">{phone}</a></text>
          <text x="20" y="155" fontFamily="Helvetica" fontSize="13" fill="white">Email: <a href={`mailto:${email}`} fill="white">{email}</a></text>
          <line x1="20" y1="167" x2="280" y2="167" stroke="white" strokeWidth="1"/>
          <text x="20" y="195"><a href="https://www.thebalder.com" fontFamily="Cardo" fontSize="15" fontStyle="italic" fill="white">www.thebalder.com</a></text>
          {photo && <image x="390" y="30" width="120" href={photo.data}/>}
          <text x="450" y="175" textAnchor="middle" fontFamily="Helvetica" fontSize="15" fill="white">{name}</text>
          <text x="450" y="195" textAnchor="middle" fontFamily="Helvetica" fontSize="13" fill="white">{position}</text>
          <rect y="215" width="100%" height="200" fill="#ffffff"/>
          <Logos />
        </svg>
      </div>
    )
  }
)
export default SVGBalder;
