import { debounce } from 'lodash';
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";
import {gql} from "graphql.macro";
import {useQuery} from "@apollo/react-hooks";

import ListRow from "../RecordList/ListRow";
import Editor from "../../containers/Editor";
import { useNavigate } from "react-router-dom";
import { useReferral } from "../RecordList/withReferral";

const styles = theme => ({
  paper: {
    margin: theme.spacing.unit * 2,
  },
  results: {
    marginTop: theme.spacing.unit * 2,
  },
  resultsNoData: {
    marginTop: theme.spacing.unit * 2,
  },
  resultsWrapper: {
    marginTop: theme.spacing.unit * 2,
  },
});

const SEARCH_QUERY = gql`
  query($query: String!) {
    calendar {
      search(query: $query) {
        id
        date,
        time
        excursion
        startPoi
        ref
        note
        implementer
      }
    }
  }
`;

const crmContext = { clientName: 'crm' };

const Results = withStyles(styles)(({ classes, results }) => {
  const referral = useReferral();
  const navigate = useNavigate();

  const onClick = React.useCallback((id) => {
    const args = { referral };
    if (id) {
      args.id = id;
    }

    const state = {};

    navigate(Editor.PATH.generate(args), { state });
  }, [navigate, referral]);

  if (results.length === 0) {
    return (
      <div className={classes.resultsNoData}>
        There are no results for your search.
      </div>
    );
  }

  return (
    <Table className={classes.results}>
      <TableBody>
        {results.map(row => (
          <ListRow
            key={row.id}
            record={row}
            onClick={onClick}
            short={true}
            withDate={true}
          />
        ))}
      </TableBody>
    </Table>
  );
});

const ResultsBlock = withStyles(styles)(({ classes, query }) => {
  const variables = React.useMemo(() => ({ query }), [query]);
  const { loading, data } = useQuery(SEARCH_QUERY, {
    variables,
    fetchPolicy: "network-only",
    context: crmContext,
  });

  return (
    <div className={classes.resultsWrapper}>
      {loading && <LinearProgress variant="query" />}
      {data && data.calendar ? <Results results={data.calendar.search} /> : null}
    </div>
  );
});

const ModalContent = withStyles(styles)(({ classes }) => {
  const [query, setQuery] = React.useState('');
  const debouncedSetValue = React.useMemo(() => debounce(value => setQuery(value), 250), []);
  const handleChange = React.useCallback((ev) => debouncedSetValue(ev.target.value), [debouncedSetValue]);
  return (
    <div className={classes.paper}>
      <TextField
        id="phrase"
        placeholder="What are you looking for?"
        fullWidth
        onChange={handleChange}
      />
      {query && <ResultsBlock query={query} />}
    </div>
  );
})

const SearchModal = ({ open, handleClose }) => {
  return (
    <Drawer anchor="top" open={open} onClose={handleClose}>
      <ModalContent />
    </Drawer>
  );
}

export default SearchModal;
