import React from "react";
import styled from '@emotion/styled/macro';
import Button from "@material-ui/core/Button/Button";
import ArrowBack from '@material-ui/icons/ArrowBack';
import {Link, useParams} from "react-router-dom";

import { decodeReferral } from "../RecordList/withReferral";
import CalendarPath from "../../containers/CalendarAndPlaces.path";

function BackToList({ className }) {
  const { referral } = useParams();
  const url = CalendarPath.generate(decodeReferral(referral));
  return (
    <Button
      className={className}
      color="inherit"
      component={Link}
      to={url}
    >
      <ArrowBack fontSize="small" />
      Back to the list
    </Button>
  )
}

export default styled(BackToList)`
  svg {
    margin-right: 8px;
  }
`;
