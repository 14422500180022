import { gql } from "graphql.macro";

import recordHistoryFragment from "./RecordHistory.fragment";

import recordFragment from "../RecordList/Record.fragment";

export default gql`
  fragment FullRecord on Record {
    ...Record
    ...RecordHistory
    attachments {
      id
      filename
      contentType
      path
    }
  }

  ${recordFragment}
  ${recordHistoryFragment}
`;
