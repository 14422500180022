import React from "react";
import { withProps } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import CalendarToday from '@material-ui/icons/CalendarToday';
import {Link} from "react-router-dom";
import CalendarAndPlacesPath from "../../containers/CalendarAndPlaces.path";

const styles = theme => ({
  icon: {
    marginLeft: theme.spacing.unit,
  },
});

const to = CalendarAndPlacesPath.generate({});

const Label = withStyles(styles)(({ classes }) => {
  return (
    <>
      <span>Calendar</span>
      <CalendarToday
        size="large"
        color="inherit"
        fontSize={"small"}
        className={classes.icon}
      />
    </>
  );
});

export default withProps({ color: 'inherit', children: <Label />, component: Link, to })(Button);
