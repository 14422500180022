import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { Field } from 'formik';

class Select extends React.Component {
  static propTypes = {
    field: PropTypes.shape().isRequired,
    form: PropTypes.shape().isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
  };

  static defaultProps = {
    required: false,
  };

  renderOption = ({ value, title }) => {
    return <MenuItem key={value} value={value}>{title}</MenuItem>;
  };

  render() {
    const {
      options,
      field,
      form: { errors, touched },
      required,
      label,
      helperText,
      className,
      ...props
    } = this.props;
    const name = field.name;
    const hasError = errors[name] && touched[name];
    const errorMessage = hasError ? errors[name] : null;
    return (
      <FormControl margin="dense" required={required} fullWidth error={hasError} className={className}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <MuiSelect id={name} {...field} {...props}>
          {options.map(this.renderOption)}
        </MuiSelect>
        <FormHelperText>{errorMessage || helperText || ''}</FormHelperText>
      </FormControl>
    );
  }
}

export default styled(({ name, ...props }) => <Field name={name} component={Select} {...props} />)``;
