import * as React from 'react';
import { gql } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { filter } from 'graphql-anywhere';
import {parseISO, format, startOfMonth, endOfMonth, addMonths} from 'date-fns';

import AppBar from '../AppBar';
import List from './List';
import Wrapper from './Wrapper';
import dayFragment from './List.fragment';
import Filter from '../Filter';
import Preloader from '../Preloader';
import Calendar from '../Calendar';
import {useStoredStatuses} from "../Filter/ByStatus";
import {useParams} from "react-router-dom";

const CALENDAR_QUERY = gql`
  query($place: Int, $rowsFrom: Date!, $rowsTo: Date!, $statuses: [Int]) {
    viewer {
      days:calendar(placeId: $place, from: $rowsFrom, to: $rowsTo) {
        ...FullDay
      }
      ...Places
    }
  }

  ${AppBar.fragments.places}
  ${dayFragment}
`;

function RecordList() {
  const { placeId, from, to } = useParams();
  const initialStatuses = useStoredStatuses();
  const [statuses, setStatuses] = React.useState(initialStatuses);

  const variables = React.useMemo(() => {
    const now = new Date();
    const fmt = d => format(d, 'yyyy-MM-dd');
    const res = {
      statuses,
      place: placeId ? parseInt(placeId) : null,
      calFrom: fmt(startOfMonth(now)),
      calTo: fmt(endOfMonth(addMonths(now, 2))),
      rowsFrom: fmt(now),
      rowsTo: fmt(now),
    };

    if (from) {
      const parsedFrom = parseISO(from);
      res.calFrom = fmt(startOfMonth(parsedFrom));
      res.calTo =  fmt(endOfMonth(addMonths(parsedFrom, 2)));
      res.rowsFrom = from;
      res.rowsTo = from;
    }

    if (to) {
      res.rowsTo = to;
    }

    return res;
  }, [from, placeId, statuses, to]);

  const { loading, data } = useQuery(CALENDAR_QUERY, {
    variables,
    pollInterval: 15000,
    fetchPolicy: "network-only",
  });

  const { rowsFrom, rowsTo, place } = variables;
  const { viewer } = data;
  const range = {
    from: rowsFrom,
    to: rowsTo,
    place,
    start: format(parseISO(rowsFrom), 'yyyyMM'),
  };

  const days = viewer ? viewer.days : null;
  const filtered = React.useMemo(() => {
    if (!days) return [];
    return filter(dayFragment, days);
  }, [days]);

  if (!viewer && loading) return <Preloader />;
  return (
    <>
      <AppBar user={viewer}/>
      <Calendar {...range}/>
      <Filter place={place} onChange={setStatuses} />
      <Wrapper className={loading ? 'blur' : null}>
        <List days={filtered} {...range} />
      </Wrapper>
    </>
  );
}

export default RecordList;
