import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import styled from '@emotion/styled/macro';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import { Field, getIn } from 'formik';
import {Query} from "react-apollo";

class AsyncSelect extends React.Component {
  static propTypes = {
    field: PropTypes.shape().isRequired,
    form: PropTypes.shape().isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
  };

  static defaultProps = {
    required: false,
  };

  renderOption = ({ id, title }) => {
    return <MenuItem key={id} value={id}>{title}</MenuItem>;
  };

  renderSelect = ({ data }) => {
    const {
      field: {
        value,
        ...field
      },
      path,
      ...props
    } = this.props;
    const name = field.name;
    const selectProps = omit(props, ['query', 'form', 'required', 'label', 'helperText', 'className'])
    const options = getIn(data, path, []);
    return (
      <MuiSelect id={name} value={value === null ? '' : value} {...field} {...selectProps}>
        {options.map(this.renderOption)}
      </MuiSelect>
    );
  };

  render() {
    const {
      query,
      field,
      form: { errors, touched },
      required,
      label,
      helperText,
      className,
    } = this.props;
    const name = field.name;
    const hasError = getIn(errors, name) && getIn(touched, name);
    const errorMessage = hasError ? getIn(errors, name) : null;
    return (
      <FormControl margin="dense" required={required} fullWidth error={hasError} className={className}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Query query={query}>
          {this.renderSelect}
        </Query>
        <FormHelperText>{errorMessage || helperText || ''}</FormHelperText>
      </FormControl>
    );
  }
}

export default styled(({ name, ...props }) => <Field name={name} component={AsyncSelect} {...props} />)``;
