import * as React from 'react';
import styled from '@emotion/styled/macro';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

export default styled(props => <button {...props}><ArrowDownward /></button>)`
  border: 0 solid #ccc;
  background: none;
  cursor: pointer;
  outline: none!important;
  
  & > svg {
    opacity: 0.5;
  }
  
  &:hover > svg {
    opacity: 1;
  }
`;
