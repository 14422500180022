import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import { gql } from 'graphql.macro';
import { Query } from "react-apollo";

import CalendarAndPlaces from '../containers/CalendarAndPlaces';
import Editor from '../containers/Editor';
import Signatures from '../containers/Signatures';

import GlobalStyles from './GlobalStyles';
import SignIn from "./SignIn";
import Preloader from "./Preloader";

const VIEWER_QUERY = gql`
  {
    viewer {
      login
      name
      role
    }
  }
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

class App extends Component {
  renderApp = ({ loading, error, data }) => {
    if (loading) return <Preloader />;
    if (error) return <div>Error</div>;

    const { viewer } = data;
    if (!viewer) return <SignIn />;

    return (
      <Wrapper>
        <Routes>
          <Route exact path={Signatures.PATH.route} element={Signatures.element} />
          <Route exact path={Editor.PATH.route} element={Editor.element} />
          <Route exact path={CalendarAndPlaces.PATH.route[0]} element={CalendarAndPlaces.element} />
          <Route exact path={CalendarAndPlaces.PATH.route[1]} element={CalendarAndPlaces.element} />
          <Route exact path={CalendarAndPlaces.PATH.route[2]} element={CalendarAndPlaces.element} />
        </Routes>
      </Wrapper>
    );
  };

  render() {
    return (
      <>
        <GlobalStyles />
        <Router>
          <Query query={VIEWER_QUERY}>
            {this.renderApp}
          </Query>
        </Router>
      </>
    );
  }
}

export default App;
