import * as React from 'react';
import styled from '@emotion/styled/macro';
import AppBar from "@material-ui/core/AppBar/AppBar";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";

import ByStatus from "./ByStatus";
import Shortcuts from "./Shortcuts";
import { MOBILE } from "../media-query";

function Filter({ className, onChange, place }) {
  const isMobile = useMediaQuery(MOBILE);

  return (
    <AppBar position="sticky" color="default" className={classNames(className, isMobile ? 'mobile' : null)}>
      <Shortcuts place={place}/>
      <ByStatus onChange={onChange}/>
    </AppBar>
  );
}

export default styled(Filter)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &.mobile {
      flex-direction: column;

      & > ${ByStatus} {
        margin: 0 1.25em;
      }
    }
  }
`;
