import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TimeCell from './TimeCell';

const Row = styled(TableRow)`
  &.new {
    background: rgba(255, 0, 0, 0.1);
  }

  &.pending {
    background: rgba(0, 0, 255, 0.1);
  }

  &.resolved {

  }

  &.cancelled {
    opacity: 0.5;

    & > td > div > span {
      text-decoration: line-through;
    }
  }
`;

const Cell = styled(({ children, width, ...props }) => (
  <TableCell {...props}>
    <div title={children}>
      <span>{children}</span>
    </div>
  </TableCell>
))`
  width: ${props => props.width ? `${props.width}px` : 'auto'};

  && {
    padding-right: 24px;
  }

  & > div {
    position: relative;
    height: 1.2em;
    & > span {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }
  }
`;

const classes = {
  0: 'new',
  10: 'pending',
  20: 'resolver',
  30: 'cancelled',
};

const ListRow = ({ record, onClick, header, short, withDate }) => {
  const onClickCallback = React.useCallback(() => onClick(record.id), [onClick, record.id]);
  return (
    <Row hover={!header} onClick={onClickCallback} className={!header && classes[record.status]}>
      {!short && <Cell width={100}>{record.place.name}</Cell>}
      {withDate && <Cell>{record.date ? record.date.slice(0, 10) : ""}</Cell>}
      <TimeCell time={record.time}/>
      <Cell>{record.excursion}</Cell>
      <Cell>{record.startPoi}</Cell>
      <Cell width={30}>{record.pax}</Cell>
      <Cell>{record.ref}</Cell>
      <Cell>{record.note}</Cell>
      <Cell width={150}>{record.implementer}</Cell>
      {!short && <Cell width={150}>{record.manager.name}</Cell>}
    </Row>
  );
};

ListRow.propTypes = {
  // record: propType(fragment).isRequired,
  header: PropTypes.bool,
  onClick: PropTypes.func,
  withDate: PropTypes.bool,
};

ListRow.defaultProps = {
  header: false,
  onClick: null,
  withDate: false,
};

export default ListRow;
