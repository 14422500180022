import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { propType } from 'graphql-anywhere'
import { get } from 'lodash'

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import fragment from './Record.fragment';
import Button from "@material-ui/core/Button";

const Row = styled(TableRow)`
  &.new {
    background: rgba(255, 0, 0, 0.1);
  }

  &.pending {
    background: rgba(0, 0, 255, 0.1);
  }

  &.resolved {

  }

  &.cancelled {
    opacity: 0.5;

    & > td > dl > dd {
      text-decoration: line-through;
    }
  }

  & > td > dl {
    & > dt {
      font-size: 80%;
      color: #777;
    }

    & > dd {
      margin: 0 0 1em 0;
      &:last-of-type {
        margin: 0;
      }
    }
  }
`;

const classes = {
  0: 'new',
  10: 'pending',
  20: 'resolver',
  30: 'cancelled',
};

const rows = ['startPoi', 'pax', 'ref', 'note', 'manager.name'];

const Actions = styled.div`
  text-align: center;
`;

const Short = ({ record }) => {
  return (
    <>
      <div>
        <strong>{record.place.name} {record.time}</strong>
        <br />
        {record.excursion}
        <br />
        <i>{record.implementer}</i>
      </div>
    </>
  );
};

const Long = ({ action, record, headers, ...props }) => {
  return (
    <>
      <dl {...props}>
        {rows.filter(row => get(record, row)).map(row => (
          <React.Fragment key={row}>
            <dt>{get(headers, row)}</dt>
            <dd>{get(record, row)}</dd>
          </React.Fragment>
        ))}
      </dl>
      <Actions>
        <Button onClick={action} color="primary">
          Edit
        </Button>
      </Actions>
    </>
  );
};

const CardRow = ({ record, onClick, header, headers }) => {
  const [expanded, setExpanded] = React.useState(false);
  const onClickCallback = React.useCallback(() => onClick(record.id), [onClick, record.id]);
  const expand = React.useCallback(() => setExpanded(v => !v), []);
  return (
    <Row hover={!header} onClick={expand} className={!header && classes[record.status]}>
      <TableCell>
        <Short record={record} />
        {expanded ? (
          <Long record={record} headers={headers} action={onClickCallback} />
        ) : null}
      </TableCell>
    </Row>
  );
};

CardRow.propTypes = {
  record: propType(fragment).isRequired,
  header: PropTypes.bool,
  onClick: PropTypes.func,
  headers: PropTypes.object.isRequired,
};

CardRow.defaultProps = {
  header: false,
  onClick: null,
};

export default CardRow;
