import * as React from "react";
import { generatePath } from "react-router-dom";

import { Layout } from '../components/Signatures/Layout';


const Signatures = {
  PATH: {
    route: '/signatures',
    generate(args) {
      return generatePath(this.route, args);
    }
  },
  element: <Layout />,
};

export default Signatures;
