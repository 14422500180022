import * as React from 'react';
import styled from "@emotion/styled/macro";
import PropType from "prop-types";
import classNames from "classnames";
import { propType } from 'graphql-anywhere'

import dayFragment from "./Day.fragment";
import DayStat from "./DayStat";
import { isSameDay, isWeekend, parseISO, getDate } from "date-fns";

const Weekday = styled.div`
  background: rgb(238, 238, 238);
`;

const Weekend = styled.div`
  background: rgb(255, 216, 194);
`;

class BareDay extends React.PureComponent {
  static propTypes = {
    selected: PropType.bool.isRequired,
    hovered: PropType.bool.isRequired,
    date: PropType.string.isRequired,
    id: PropType.string.isRequired,
    onStartSelection: PropType.func.isRequired,
    onChangeSelection: PropType.func.isRequired,
    data: propType(dayFragment),
  };

  onClick = () => {
    const { onStartSelection, date } = this.props;
    onStartSelection(getDate(parseISO(date)));
  };

  onMouseOver = () => {
    const { onChangeSelection, date } = this.props;
    onChangeSelection(getDate(parseISO(date)));
  };

  onMouseOut = () => {
    const { onChangeSelection } = this.props;
    onChangeSelection(null);
  };

  render() {
    const {
      data,
      date,
      className, selected, hovered,
    } = this.props;

    const day = parseISO(date);
    const DayCmp = isWeekend(day) ? Weekend : Weekday;
    const modificators = {
      selected,
      hovered,
      today: isSameDay(day, new Date()),
    };

    return (
      <DayCmp
        className={classNames(className, modificators)}
        onMouseDown={this.onClick}
        onMouseOver={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onTouchStart={this.onClick}
      >
        {data ? <DayStat data={data} /> : null}
        {getDate(day)}
      </DayCmp>
    );
  }
}

const Day = styled(BareDay)`
  width: calc(100% / 7 - 2px);
  margin: 1px;
  padding: 0.5em 0;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  
  &.selected {
    padding-bottom: calc(0.5em - 2px);
    border-bottom: 2px solid #A0E27A;
  }
  
  &.hovered {
    background: #FFFFDC;
  }
  
  &:hover {
    background: #FFFFAF;
  }

  &.today {
    font-weight: bold;
  }
`;

export default Day;
