import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik, Form } from 'formik';
import { gql } from 'graphql.macro';
import {Mutation} from "react-apollo";

import Input from '../Form/Input';
import FormError from '../Form/FormError';
import schema from './schema';

const SIGNIN_MUTATION = gql`
  mutation SignIn($company: String!, $login: String!, $password: String!) {
    token:signin(company: $company, login: $login, password: $password)
  }
`;

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class SignInForm extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  setToken = ({ token }) => {
    window.localStorage.setItem('token', token);
    window.location.reload();
  };

  onError = (error) => {};

  onSubmit = mutate => async (values, { setSubmitting }) => {
    const variables = schema.cast(values);
    await mutate({ variables });
    window.localStorage.setItem('company', variables.company);
    setSubmitting(false);
  };

  renderError = ({ loading, error }) => {
    if (!error || loading) return null;
    const [{ message }] = error.graphQLErrors;
    return <FormError>{message}</FormError>;
  };

  renderFormik = (mutate, result) => {
    const { classes } = this.props;
    const company = window.localStorage.getItem('company') || '';
    return (
      <Formik
        initialValues={{ company, login: '', password: '' }}
        onSubmit={this.onSubmit(mutate)}
        validationSchema={schema}
      >
        {({ isSubmitting }) => (
          <Form className={classes.form}>
            {this.renderError(result)}
            <Input label="Company" name="company" autoComplete="company" placeholder="EasyTravel" />
            <Input label="Login" name="login" autoComplete="login" />
            <Input label="Password" type="password" name="password" autoComplete="password" />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isSubmitting}
            >
              Sign in
            </Button>
          </Form>
        )}
      </Formik>
    )
  };

  render() {
    return (
      <Mutation mutation={SIGNIN_MUTATION} onCompleted={this.setToken} onError={this.onError}>
        {this.renderFormik}
      </Mutation>
    );
  }
}

export default withStyles(styles)(SignInForm);
