import React from "react";
import { withProps } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import ExitToApp from '@material-ui/icons/ExitToApp';

function logout() {
  window.localStorage.removeItem('token');
  window.location.reload();
}

const styles = theme => ({
  icon: {
    marginLeft: theme.spacing.unit,
  },
});

const Label = withStyles(styles)(({ classes }) => (
  <>
    Logout
    <ExitToApp fontSize={"small"} className={classes.icon} />
  </>
));

export default withProps({ color: 'inherit', onClick: logout, children: <Label /> })(Button);
