import * as React from 'react';
import { debounce } from 'lodash';
import styled from '@emotion/styled/macro';
import {
  format, parse, parseISO,
  getMonth, getYear, getDate,
  addMonths,
  endOfMonth, startOfMonth,
  min, max,
  isAfter, isBefore, isEqual,
} from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';

import Button from './Button';
import Month from './Month';
import HorizontalCalendar from './HorizontalCalendar';
import VerticalCalendar from './VerticalCalendar';
import CalendarAndPlaces from '../../containers/CalendarAndPlaces';
import { MOBILE } from "../media-query";

const baseDate = new Date(2000, 0, 1);

const isOrEqual = (fn, d1, d2) => isEqual(d1, d2) || fn(d1, d2);

const getMonthArgs = (date, { from, to, place, selection, onStartSelection, onChangeSelection }) => {
  const fromDate = parseISO(from);
  const toDate = parseISO(to);
  const monthStart = startOfMonth(date);
  const monthEnd = endOfMonth(date);
  const result = {
    onStartSelection,
    onChangeSelection,
    key: format(date, 'yyyyMM'),
    place,
    year: getYear(date),
    month: getMonth(date),
    selectedFrom: null,
    selectedTo: null,
    selectionStart: null,
    selectionEnd: null,
  };

  const selectionStart = min([selection.start, selection.end]);
  const selectionEnd = max([selection.start, selection.end]);
  if (isOrEqual(isBefore, selectionStart, monthEnd) && isOrEqual(isAfter, selectionEnd, monthStart)) {
    result.selectionStart = selectionStart;
    result.selectionEnd = selectionEnd;
  }

  if (isOrEqual(isBefore, fromDate, monthEnd) && isOrEqual(isAfter, toDate, monthStart)) {
    result.selectedFrom = getDate(max([fromDate, monthStart]));
    result.selectedTo = getDate(min([toDate, monthEnd]));
  }

  return result;
};

const useSelection = (onEnd) => {
  const [selection, setSelection] = React.useState({ start: null, end: null });

  const onEndSelection = React.useCallback(
    () => {
      if (!selection.start) return;
      setSelection({ start: null, end: null });

      if (!selection.start || !selection.end) return;

      const selectionStart = min([selection.start, selection.end]);
      const selectionEnd = max([selection.start, selection.end]);
      const fmt = d => format(d, 'yyyy-MM-dd');
      onEnd(fmt(selectionStart), fmt(selectionEnd));
    },
    [selection, onEnd],
  );

  const onStartSelection = React.useCallback((year, month, day) => {
    const date = new Date(year, month, day);
    setSelection({ start: date, end: date });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeSelection = React.useCallback(debounce((year, month, day) => {
    setSelection((value) => {
      const newEnd = day ? new Date(year, month, day) : null;
      if (value.start === null || isEqual(value.end, newEnd)) return value;
      return { ...value, end: newEnd };
    });
  }, 100), []);

  React.useEffect(() => {
    document.addEventListener('mouseup', onEndSelection);
    document.addEventListener('touchend', onEndSelection);
    return () => {
      document.removeEventListener('mouseup', onEndSelection);
      document.removeEventListener('touchend', onEndSelection);
    }
  }, [onEndSelection]);

  return [selection, onStartSelection, onChangeSelection];
};

const useMonths = (initial, args) => {
  const initialDate = React.useMemo(
    () => parse(initial || format(new Date(), 'yyyyMM'), 'yyyyMM', baseDate),
    [initial],
  );
  const [start, setStart] = React.useState(initialDate);
  const prev = React.useCallback(() => setStart((v) => (addMonths(v, -1))), []);
  const next = React.useCallback(() => setStart((v) => (addMonths(v, 1))), []);
  const months = React.useMemo(
    () => [0, 1, 2].map(idx => getMonthArgs(addMonths(start, idx), args)),
    [start, args],
  );

  return [months, prev, next];
};

function Calendar({ className, start: initialStart, from, to, place }) {
  const params = useParams()
  const navigate = useNavigate();
  const isMobile = useMediaQuery(MOBILE);
  const onSelectionEnd = React.useCallback((from, to) => {
    const route = CalendarAndPlaces.PATH.generate({
      ...params,
      from,
      to,
    });

    navigate(route);
  }, [params, navigate]);
  const [selection, onStartSelection, onChangeSelection] = useSelection(onSelectionEnd);

  const ContainerCmp = isMobile ? VerticalCalendar : HorizontalCalendar;
  const baseArgs = React.useMemo(() => ({
    from,
    to,
    place,
    selection,
    onStartSelection,
    onChangeSelection,
  }), [from, to, place, selection, onStartSelection, onChangeSelection]);

  const [months, prev, next] = useMonths(initialStart, baseArgs);

  return (
    <ContainerCmp className={className}>
      <Button onClick={prev} />
      {months.map(args => <Month {...args} />)}
      <Button onClick={next} />
    </ContainerCmp>
  );
}

export default styled(Calendar)`
  display: flex;
  user-select: none;

  & > ${Month} {
    flex: 1;
    margin: 1em 2em;
  }
`;
