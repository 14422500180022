import { gql } from "graphql.macro";

export default gql`
  fragment DayStat on Day {
    id
    date

    statistics {
      status
      count
    }
  }
`;
