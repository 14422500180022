import * as React from 'react';
import styled from '@emotion/styled/macro';
import diff from 'fast-diff';

import Statuses from '../../data/statuses';
import HistoryEntry from './HistoryEntry';

const diffClasses = {
  '-1': 'delete',
  '1': 'insert',
  '0': 'equal',
};
const Diff = styled(React.memo(({ field, before, after, className }) => {
  const ops = diff(before, after);
  if (field === 'status') {
    return (
      <pre className={className}>
        <span className="delete">{Statuses.get(parseInt(before, 10))}</span>
        <span className="insert">{Statuses.get(parseInt(after, 10))}</span>
      </pre>
    );
  }

  const renderOp = ([type, text], idx) => <span key={idx} className={diffClasses[type]}>{text}</span>;
  return (
    <pre className={className}>
      {ops.map(renderOp)}
    </pre>
  );
}))`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;

  & > .insert {
    color: #008A00;
  }

  & > .delete {
    color: #8A0000;
    text-decoration: line-through;
  }
`;

class BareHistory extends React.PureComponent {
  renderEntry = ({ id, insertedAt, field, before, after, manager: author }, idx, all) => {
    return (
      <HistoryEntry key={id} date={insertedAt} author={author} collapsed={idx < all.length - 1}>
        Field <i>{field}</i> has been changed.
        <Diff field={field} before={before} after={after} />
      </HistoryEntry>
    );
  };

  render() {
    const { className, owner, created, entries } = this.props;
    return (
      <div className={className}>
        {owner && <HistoryEntry date={created} author={owner} collapsed={entries && entries.length > 0}>
          created
        </HistoryEntry>}
        {(entries || []).map(this.renderEntry)}
      </div>
    );
  }
}

export default styled(BareHistory)`
  padding: 1em;

  @media print {
    display: none;
  }
`;
