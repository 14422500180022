import * as React from 'react';
import PropType from 'prop-types';
import { get, keyBy } from 'lodash';
import styled from "@emotion/styled/macro";
import { gql } from 'graphql.macro';
import { Query } from "react-apollo";
import memoize from "memoize-one";
import {
  startOfWeek, endOfMonth, endOfWeek, eachDayOfInterval, format,
  getMonth, isWithinInterval, setDate,
} from 'date-fns';

import Day from './Day';
import dayFragment from './Day.fragment';
import Filler from "./Filler";

const MONTH_QUERY = gql`
  query($from: Date!, $to: Date!, $placeId: Int) {
    viewer {
      calendar(from: $from, to: $to, placeId: $placeId) {
        ...DayStat
      }
    }
  }

  ${dayFragment}
`;

class BareMonth extends React.PureComponent {
  static propTypes = {
    year: PropType.number.isRequired,
    month: PropType.number.isRequired,
  };

  onStartSelection = (day) => {
    const { onStartSelection, month, year } = this.props;
    onStartSelection(year, month, day);
  };

  onChangeSelection = (day) => {
    const { onChangeSelection, month, year } = this.props;
    onChangeSelection(year, month, day);
  };

  getQueryVariables = memoize((from, to, placeId) => ({
    from: format(from, 'yyyy-MM-dd'),
    to: format(to, 'yyyy-MM-dd'),
    placeId,
  }));

  getStat = memoize((data) => keyBy(get(data, 'calendar', []), 'id'));

  renderQueryResult = ({ data, variables: { placeId } }) => {
    const stat = this.getStat(data && data.viewer);
    const { className, year, month, selectedFrom, selectedTo, selectionStart, selectionEnd } = this.props;
    const date = new Date(year, month, 1);
    const range = {
      start: startOfWeek(date, { weekStartsOn: 1 }),
      end: endOfWeek(endOfMonth(date), { weekStartsOn: 1 }),
    };

    const selectedRange = selectedFrom && selectedTo ?
      { start: setDate(date, selectedFrom), end: setDate(date, selectedTo) }
      : null;

    const hoverRange = selectionStart && selectionEnd ?
      { start: selectionStart, end: selectionEnd }
      : null;

    const days = eachDayOfInterval(range).map(day => {
      const key = `${placeId || '*'}:${format(day, 'yyyyMMdd')}`;
      return {
        key,
        id: key,
        date: format(day, 'yyyy-MM-dd'),
        data: get(stat, key),
        onStartSelection: this.onStartSelection,
        onChangeSelection: this.onChangeSelection,
        filler: getMonth(day) !== month,
        selected: selectedRange ? isWithinInterval(day, selectedRange) : false,
        hovered: hoverRange ? isWithinInterval(day, hoverRange) : false,
      };
    });

    return (
      <div className={className}>
        <header>{format(date, 'MMMM yyyy')}</header>
        <div>
          {days.map(
            ({ filler, key, ...props }) => (filler ? <Filler key={key} /> : <Day key={key} {...props} />),
          )}
        </div>
      </div>
    );
  };

  render() {
    const { year, month, place } = this.props;
    const startOfMonth = new Date(year, month, 1);
    const variables = this.getQueryVariables(startOfMonth, endOfMonth(startOfMonth), place);

    return (
      <Query query={MONTH_QUERY} variables={variables} pollInterval={15000}>
        {this.renderQueryResult}
      </Query>
    )
  }
}

export default styled(BareMonth)`
  & > header {
    text-align: center;
    font-weight: bold;
    line-height: 2;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
  }
`;
