import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { DatePicker } from 'material-ui-pickers';
import { Field } from 'formik';

class Date extends React.Component {
  static propTypes = {
    field: PropTypes.shape().isRequired,
    form: PropTypes.shape().isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
  };

  static defaultProps = {
    required: false,
  };

  render() {
    const { field, form, ...other } = this.props;
    const currentError = form.errors[field.name];
    return (
      <DatePicker
        autoOk
        margin="dense"
        keyboard
        clearable
        name={field.name}
        value={field.value}
        format="yyyy-MM-dd"
        helperText={currentError || ' '}
        error={Boolean(currentError)}
        onError={(_, error) => form.setFieldError(field.name, error)}
        onChange={date => form.setFieldValue(field.name, date, true)}
        mask={value => (value ? [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/] : [])}
        {...other}
      />
    );
  }
}

export default styled(({ name, ...props }) => <Field name={name} component={Date} {...props} />)`
`;
