import * as React from "react";
import {Form as FormikForm, Formik} from 'formik';
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-GB";
import Input from "../Form/Input";
import ImageField from "../Form/ImageField";

const Updater = ({ data, onUpdate }) => {
  React.useEffect(() => {
    onUpdate(data);
  }, [data, onUpdate]);

  return null;
};

const inputProps = {
  accept: "image/png, image/jpeg",
}

const Editor = ({ data, onUpdate }) => {
  const initialValues = React.useMemo(() => ({
    name: '',
    position: '',
    phone: '',
    email: '',
    photo: null,
    ...(data || {}),
  }), [data])

  const renderForm = React.useCallback((formik) => {
    const { values } = formik;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <FormikForm>
          <Updater data={values} onUpdate={onUpdate} />
          <Input label="Name" name="name" className="name" />
          <Input label="Position" name="position" className="position" />
          <Input label="Phone" name="phone" className="phone" />
          <Input label="Email" name="email" className="email" />
          <ImageField inputProps={inputProps} label="Photo" name="photo" className="photo" />
        </FormikForm>
      </MuiPickersUtilsProvider>
    );
  }, [onUpdate]);

  return (
    <Formik initialValues={initialValues}>{renderForm}</Formik>
  );
}

export default Editor;
