import styled from '@emotion/styled/macro';
import Button from './Button';

export default styled.div`
  flex-direction: column;
  
  & > ${Button} {
    height: 64px;
  
    &:first-of-type {
      border-bottom-width: 1px;
      
      & > svg {
        transform: rotate(180deg);
      }
    }
    
    &:last-of-type {
      border-top-width: 1px;
      & > svg {
        transform: rotate(0deg);
      }
    }
  }
`;
