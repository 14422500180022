import * as React from 'react';
import { BitStream } from 'bit-buffer';
import { encode, decode } from 'base64-arraybuffer-es6';
import { trimEnd } from 'lodash';
import {useParams} from 'react-router-dom';
import { addDays, differenceInDays, format, parseISO } from 'date-fns';

const beginning = parseISO('2014-01-01');
const sizes = [5, 15, 13];

export function encodeReferral(placeId, from, to) {
  const values = [
    placeId,
    from && differenceInDays(parseISO(from), beginning),
    to && differenceInDays(parseISO(to), parseISO(from)),
  ];
  const totalSize = values.reduce((sum, val, idx) => sum + (val ? sizes[idx] : 0), 0) + sizes.length;
  const buffer = new ArrayBuffer(Math.ceil(totalSize / 8));
  const bitStream = new BitStream(buffer);
  values.forEach((value => bitStream.writeBoolean(value)));
  values.forEach(((value, idx) => value && bitStream.writeBits(value, sizes[idx])));
  return trimEnd(encode(buffer), '=').replace(/\+/g, '_').replace(/\//g, '-');
}

export function decodeReferral(hash) {
  const buffer = decode(hash.replace(/_/g, '+').replace(/-/g, '/'));
  const bitStream = new BitStream(buffer);
  const [placeId, from, to] = [
    bitStream.readBoolean(),
    bitStream.readBoolean(),
    bitStream.readBoolean(),
  ].map((flag, idx) => flag ? bitStream.readBits(sizes[idx], false) : null);

  const result = {};
  if (placeId) {
    result.placeId = placeId;
  }

  if (from) {
    result.from = format(addDays(beginning, from), 'yyyy-MM-dd');
  }

  if (to) {
    result.to = format(addDays(parseISO(result.from), to), 'yyyy-MM-dd');
  }

  return result;
}

export const useReferral = () => {
  const { placeId, from, to } = useParams();

  return React.useMemo(() => encodeReferral(placeId, from, to), [placeId, from, to]);
}
