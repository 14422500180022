import * as React from "react";
import {generatePath, useLocation, useParams} from "react-router-dom";
import { gql } from 'graphql.macro';
import { Query } from "react-apollo";
import { filter } from 'graphql-anywhere';

import EditorComponent from '../components/Editor';
import fullRecordFragment from '../components/Editor/FullRecord.fragment';
import Preloader from "../components/Preloader";
import { decodeReferral } from "../components/RecordList/withReferral";

const RECORD_QUERY = gql`
  query($id: ID!) {
    viewer {
      role
      record(id: $id) {
        ...FullRecord
      }
    }
  }

  ${fullRecordFragment}
`;

function createNew(place = null, date = null) {
  return {
    viewer: {
      record: {
        place: {
          id: place,
        },
        date,
        status: 0,
      },
    },
  };
}

function renderEditor({ loading, error, data }) {
  const { viewer } = data;
  if (!viewer && loading) return <Preloader />;
  const record = viewer && viewer.record ? filter(fullRecordFragment, viewer.record) : null;
  return <EditorComponent record={record} userRole={viewer.role}/>;
}

const EditorContainer = () => {
  const { id, referral } = useParams();
  const { state } = useLocation();
  const {
    placeId
  } = decodeReferral(referral);

  if (id) {
    return (
      <Query query={RECORD_QUERY} variables={{ id }} fetchPolicy="network-only">
        {renderEditor}
      </Query>
    );
  }

  return renderEditor({ loading: false, data: createNew(placeId, state && state.date) });
};

const Editor = {
  element: <EditorContainer />,
  PATH: {
    route: '/editor/:referral/:id?',
    generate(args) {
      return generatePath(this.route, args);
    }
  },
}

export default Editor;
