import { generatePath } from "react-router-dom";

const CalendarAndPlaces = {
  route: [
    '/records/all/:from?/:to?',
    '/records/:placeId/:from?/:to?',
    '/',
  ],
  generate(args) {
    if (args.placeId) {
      return generatePath(this.route[1], args)
    }

    return generatePath(this.route[0], args)
  }
};

export default CalendarAndPlaces;
