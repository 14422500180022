import React from "react";
import { withProps } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import Settings from '@material-ui/icons/Settings';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";
import Signatures from "../../containers/Signatures";

const styles = theme => ({
  icon: {
    marginLeft: theme.spacing.unit,
  },
});

const Label = withStyles(styles)(({ classes }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const to = Signatures.PATH.generate({});
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Settings
        size="large"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        fontSize={"small"}
        className={classes.icon}
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to={to}>Signatures</MenuItem>
      </Menu>
    </>
  );
});

export default withProps({ color: 'inherit', children: <Label /> })(Button);
