import * as React from 'react';
import styled from '@emotion/styled/macro';
import { toPairs, mapValues } from 'lodash';
import { useLocalStorage } from "react-use";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const nameToValue = {
  new: 0,
  pending: 10,
  resolved: 20,
  cancelled: 30,
};

const defaultState = {
  new: true,
  pending: true,
  resolved: true,
  cancelled: false,
};

const toList = (value) => toPairs(value).filter(([_, v]) => v).map(([k, _]) => nameToValue[k])

const storageOptions = {
  raw: false,
  serializer: (value) => {
    const list = toList(value);
    return JSON.stringify(list);
  },
  deserializer: (value) => {
    const parsed = JSON.parse(value);
    return mapValues(nameToValue, v => parsed.includes(v));
  },
};

export const useStoredStatuses = () => {
  const [statuses] = useLocalStorage('statuses', defaultState, storageOptions);
  React.useEffect(() => {
    const handler = (ev) => {
      console.log('storage', ev);
    };

    window.addEventListener("storage", handler);

    return () => window.removeEventListener("storage", handler)  }, []);
  return toList(statuses);
}

const ByStatus = ({ className, onChange }) => {
  const [state, setState] = useLocalStorage('statuses', defaultState, storageOptions);
  React.useEffect(() => {
    onChange(toList(state));
  }, [onChange, state]);

  const handleChange = React.useCallback((ev) => {
      const value = ev.target.value;
      setState({
        ...state,
        [value]: !state[value],
      })
    },
    [setState, state],
  );

  return (
    <FormGroup className={className} row>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.new}
            onChange={handleChange}
            value={"new"}
          />
        }
        label="New"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.pending}
            onChange={handleChange}
            value={"pending"}
            color="primary"
          />
        }
        label="Pending"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.resolved}
            onChange={handleChange}
            value={"resolved"}
            color="default"
          />
        }
        label="Resolved"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.cancelled}
            onChange={handleChange}
            value={"cancelled"}
            color="default"
          />
        }
        label="Cancelled"
      />
    </FormGroup>
  );

};

export default styled(ByStatus)`
  display: flex;
  margin-left: auto;

  & > label {
    flex: 1;
    width: 110px;
  }
`;
