const statuses = [
  [0, 'New'],
  [10, 'Pending'],
  [20, 'Resolved'],
  [30, 'Cancelled'],
];

const statusesMap = new Map(statuses);

export default statusesMap;
