import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import { Field } from 'formik';
import MuiInput from "@material-ui/core/Input/Input";

function ImageField({
  field: {name, value, ...field},
  form: {errors, touched, setFieldValue},
  required,
  label,
  helperText,
  className,
  ...props
}) {
  const handleFileInputChange = (e) => {
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = function (onLoadEvent) {
      const img = new Image();

      img.onload = function (event) {
        const max = Math.max(img.width, img.height);
        const scale = max / 160;
        const width = img.width / scale;
        const height = img.height / scale;

        // Dynamically create a canvas element
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");

        ctx.drawImage(img, 0, 0, width, height);

        setFieldValue(name, {
          name: imageFile.name,
          data: canvas.toDataURL(imageFile.type),
        });
      }

      img.src = onLoadEvent.target.result;
    }
    reader.readAsDataURL(imageFile);
  }

  const hasError = errors[name] && touched[name];
  const errorMessage = hasError ? errors[name] : null;
  return (
    <FormControl margin="dense" required={required} fullWidth error={hasError} className={className}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <MuiInput id={name} type="file" name={name} {...field} {...props} onChange={handleFileInputChange} value="" />
      <FormHelperText>{errorMessage || (value?.name) || helperText || ''}</FormHelperText>
    </FormControl>
  );
}

ImageField.defaultProps = {
  required: false,
};

ImageField.propTypes = {
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default styled(({ name, ...props }) => <Field name={name} component={ImageField} {...props} />)``;
