import { gql } from "graphql.macro";

export default gql`
  fragment RecordHistory on Record {
    manager {
      name
      phone
    }
    insertedAt
    updatedAt
    history {
      id
      insertedAt
      after
      before
      field
      manager {
        name
        phone
      }
    }
  }
`;
