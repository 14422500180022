import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LocationCity from '@material-ui/icons/LocationCity';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

import Logout from "./Logout";
import Search from "./Search";
import { Link, useParams } from "react-router-dom";
import CalendarAndPlaces from "../../containers/CalendarAndPlaces";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const PlaceItem = ({ id, name, value, handleClose }) => {
  const params = useParams();
  const path = CalendarAndPlaces.PATH.generate({ ...params, placeId: id });
  return (
    <MenuItem
      component={Link}
      value={value || `/${id}`}
      to={path}
      onClick={handleClose}
    >{name}</MenuItem>
  );
}

const PlaceMenu = ({ places }) => {
  const { placeId } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const selected = places.find(p => p.id === placeId);

  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Button
        onClick={handleClick}
        color="inherit"
        className="toggle"
      >
        <LocationCity />
        <span>{selected ? selected.name : 'All'}</span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <PlaceItem name="All" value="/" handleClose={handleClose} />
        {places.map(place => <PlaceItem key={place.id} handleClose={handleClose} {...place} />)}
      </Menu>
    </>
  );
};

const styles = theme => ({
  search: {
    marginLeft: 'auto',
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  logout: {
    marginLeft: '0',
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  tabHome: {
    minWidth: theme.spacing.unit * 8,
  },
  tabPlace: {
    minWidth: theme.spacing.unit * 10,
  },
});

class BareTabs extends React.Component {
  render() {
    const { classes, className, places } = this.props;

    return (
      <div className={className}>
        <PlaceMenu places={places} />
        <Search className={classes.search} />
        <Logout className={classes.logout} />
      </div>
    );
  }
}

BareTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  places: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default styled(withStyles(styles)(BareTabs))`
  display: flex;
  flex-grow: 1;

  & svg {
    margin-right: 0.3em;
  }
`;
