import * as React from 'react';
import styled from '@emotion/styled/macro';

import Logo from '../Logo';

const Preloader = (props) => <div {...props}><Logo /></div>;

export default styled(Preloader)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  
  & > img {
    width: 50%;
  }
`;
