import React from "react";
import { withProps } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import SearchIcon from '@material-ui/icons/Search';
import SearchModal from "../SearchModal";

const styles = theme => ({
  icon: {
    marginLeft: theme.spacing.unit,
  },
});

const Search = withStyles(styles)(({ classes }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = React.useCallback(() => setIsOpen(true), []);
  const close = React.useCallback(() => setIsOpen(false), []);
  if (isOpen) {
    return (
      <SearchModal open={isOpen} handleClose={close} />
    );
  }

  return (
    <SearchIcon fontSize={"small"} className={classes.icon} onClick={open} />
  );
});

export default withProps({ color: 'inherit', children: <Search /> })(Button);
