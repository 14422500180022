import * as React from 'react';
import PropTypes from 'prop-types';
import { propType, filter } from 'graphql-anywhere'
import { eachDayOfInterval, format, parseISO } from 'date-fns'
import { keyBy } from 'lodash'
import styled from '@emotion/styled/macro'

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';

import { useReferral } from "./withReferral";
import ListRow from './ListRow';
import dayFragment from './List.fragment';
import recordFragment from './Record.fragment';
import Editor from '../../containers/Editor';
import {useMediaQuery} from "react-responsive";
import CardRow from "./CardRow";
import { MOBILE } from "../media-query";
import {useNavigate} from "react-router-dom";

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
  },
});

const DateCell = styled.div`
  display: flex;
  align-items: center;

  & > h2 {
    margin-right: 1em;
  }
`;

const NewRecordButton = ({ action, date }) => {
  const onClick = React.useCallback(() => action(null, date), [action, date]);
  return (
    <Fab
      variant="extended"
      size="small"
      color="secondary"
      aria-label="Add"
      onClick={onClick}
    >
      <AddIcon />
      New record
    </Fab>
  );
};

const Day = ({ action, date, days, table, headers }) => {
  if (!days[date] || days[date].records.length === 0) return (
    <TableRow>
      <TableCell colSpan={table ? 9 : 1}>There are no records</TableCell>
    </TableRow>
  );

  return days[date].records.map(row => table ? (
    <ListRow
      key={row.id}
      record={filter(recordFragment, row)}
      onClick={action}
    />
  ) : (
    <CardRow
      key={row.id}
      record={filter(recordFragment, row)}
      onClick={action}
      headers={headers}
    />
  ))
};

const headers = {
  id: null,
  date: null,
  expectedPrice: null,
  time: 'Time',
  excursion: 'Excursion',
  startPoi: 'Start POI',
  pax: 'PAX',
  ref: 'Ref',
  note: 'Note',
  implementer: 'Executor',
  manager: {name: 'Manager'},
  place: {id: null, name: 'Place'},
  status: 0,
};

const List = ({ classes, from, to, days }) => {
  const referral = useReferral();
  const isMobile = useMediaQuery(MOBILE);
  const navigate = useNavigate();

  const range = eachDayOfInterval({ start: parseISO(from), end: parseISO(to) }).map(d => format(d, 'yyyy-MM-dd'));
  const action = React.useCallback((id, date = null) => {
    const args = { referral };
    if (id) {
      args.id = id;
    }

    const state = {};
    if (date) {
      state.date = date;
    }

    navigate(Editor.PATH.generate(args), { state });
  }, [navigate, referral]);

  const byDate = React.useMemo(() => keyBy(days, 'date'), [days]);

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        {isMobile ? null : (
          <TableHead>
            <ListRow header record={headers}/>
          </TableHead>
        )}
        {range.map(date => (
          <TableBody key={date}>
            <TableRow>
              <TableCell variant="head" colSpan={isMobile ? 1 : 9}>
                <DateCell>
                  <h2>{date}</h2>
                  <NewRecordButton action={action} date={date} />
                </DateCell>
              </TableCell>
            </TableRow>
            <Day date={date} action={action} days={byDate} table={!isMobile} headers={headers} />
          </TableBody>
        ))}
      </Table>
    </Paper>
  );
};

List.propTypes = {
  classes: PropTypes.object.isRequired,
  days: PropTypes.arrayOf(propType(dayFragment).isRequired).isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default withStyles(styles)(List);
