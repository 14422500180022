import * as React from "react";
import Button from "@material-ui/core/Button";
import styled from "@emotion/styled/macro";
import SVGBalder from "./SVGBalder";
import SVGEasyTravel from "./SVGEasyTravel";

function downloadDataURL(data, name ) {
  const link = document.createElement('a');
  link.href = data;
  link.download = name;

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );

  setTimeout(() => {
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
}

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 10px;

  button {
    border: 1px solid black;
    border-radius: 0;
    border-top: none;
  }
`;

const PreviewWrapper = styled.div`
  .svg-wrapper {
    outline: 1px dotted black;
  }
`;

const Download = ({ imgRef, name = 'signature' }) => {
  const downloadSVG = React.useCallback(() => {
    const blob = new Blob([imgRef.current.innerHTML], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    downloadDataURL(url, `${name}.svg`);
  } , [imgRef, name]);

  const downloadPNG = React.useCallback(() => {
    const blob = new Blob([imgRef.current.innerHTML], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      downloadDataURL(canvas.toDataURL('image/png'), `${name}.png`);
    }

    img.src = url;
  } , [imgRef, name]);

  return (
    <DownloadWrapper>
      <Button name={"download"} onClick={downloadSVG}>
        Download SVG
      </Button>
      <Button name={"download"} onClick={downloadPNG}>
        Download PNG
      </Button>
    </DownloadWrapper>
  );
};

const Preview = ({ data }) => {
  const balderRef = React.useRef(null);
  const etRef = React.useRef(null);
  return (
    <PreviewWrapper>
      <SVGBalder {...data} ref={balderRef} />
      <Download imgRef={balderRef} name={data.name} />
      <SVGEasyTravel {...data} ref={etRef} />
      <Download imgRef={etRef} name={data.name} />
    </PreviewWrapper>
  );
};

export default Preview;
