import AppBar from "../AppBar";
import * as React from "react";
import styled from "@emotion/styled/macro";
import Editor from "./Editor";
import Preview from "./Preview";
import { useLocalStorage } from "react-use";

const viewer = {
  places: [],
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  padding: 32px;
`;

export const Layout = () => {
  const [data, setData] = useLocalStorage("signature-data", {});
  return (
    <>
      <AppBar user={viewer}/>
      <Wrapper>
        <Editor data={data} onUpdate={setData} />
        <Preview data={data} />
      </Wrapper>
    </>
  );
};

