import * as React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled/macro';
import { propType } from 'graphql-anywhere';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import Redo from '@material-ui/icons/Redo';

import attachFragment from "./Attach.fragment";

function Attach({ className, onDelete, onRevert, deleted, children: { id, filename, path, contentType } }) {
  const deleteFn = React.useCallback(() => onDelete(id), [onDelete, id]);
  const revertFn = React.useCallback(() => onRevert(id), [onRevert, id]);
  return (
    <li className={className}>
      {deleted ? (
        <Fab aria-label="Revert" onClick={revertFn}>
          <Redo />
        </Fab>
      ) : (
        <Fab aria-label="Delete" onClick={deleteFn}>
          <DeleteIcon />
        </Fab>
      )}
      <a href={`https://backoffice.easytravel.eu${path}`}>{filename}</a>
    </li>
  );
}

Attach.propTypes = {
  children: propType(attachFragment).isRequired,
  deleted: PropTypes.bool,
};

Attach.defaultProps = {
  deleted: false,
};

export default styled(React.memo(Attach))`
  opacity: ${props => props.deleted ? 0.5 : 1}; 

  & > button {
    margin-right: 16px;
  }
`;
