import * as React from "react";
import styled from "@emotion/styled/macro";
import keyBy from "lodash/keyBy";
import dropRightWhile from "lodash/dropRightWhile";

function normalizeStat(statistics) {
  const keyed = keyBy(statistics, 'status');
  return dropRightWhile(
    [0, 10, 20].map(i => [i, `status-${i}`, keyed[i] ? keyed[i].count : 0]),
    ([,, value]) => !value,
  );
}

const DayStat = styled(React.memo(function BareCount({ className, data: { statistics } }) {
  const normalized = React.useMemo(() => normalizeStat(statistics), [statistics]);
  return (
    <div className={className}>
      {normalized.map(([key, cls, value]) => <div key={cls} className={cls}>{value}</div>)}
    </div>
  );
}))`
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 0.2em;
  line-height: 1;
  font-size: 9px;
  
  & .status-0 {
    color: #FD7C35;
    font-size: 10px;
  }
  
  & .status-10 {
    color: #357CFD;
  }
  
  & .status-20 {
    color: #555;
  }
`;

export default DayStat;
