import * as yup from 'yup';
import { format } from 'date-fns';

function transformPlace(value, original) {
  if (!Number.isNaN(value)) return value;
  if (original.id === null) return null;
  return parseInt(original.id, 10);
}

export default yup.object().noUnknown().shape({
  id: yup.number().nullable().default(null),
  status: yup.number().oneOf([0, 10, 20, 30]).required(),
  date: yup
    .string()
    .transform((value, original) => original instanceof Date ? format(original, 'yyyy-MM-dd') : value)
    .matches(/^\d{4}-\d{2}-\d{2}$/, 'Wrong format')
    .required()
    .ensure(),
  time: yup.string().matches(/^(?:\d{2}:\d{2}(-\d{2}:\d{2})?|)$/, 'Wrong format').ensure(),
  excursion: yup.string().max(250).required().ensure(),
  implementer: yup.string().max(250).ensure(),
  pax: yup.number().min(1).required().default(1),
  note: yup.string().ensure(),
  ref: yup.string().max(250).ensure(),
  startPoi: yup.string().max(250).ensure(),
  expectedPrice: yup.number()
    .transform((v) => (Number.isNaN(v) ? null : v))
    .nullable()
    .default(null),
  countryId: yup
    .number()
    .transform(transformPlace)
    .nullable()
    .required(),
  attachments: yup.array().of(yup.object()).ensure(),
  new_attachments: yup.array().of(yup.mixed()).ensure(),
  removing_attachments: yup.array().of(yup.number()).ensure(),
}).from('place', 'countryId');
