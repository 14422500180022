import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Logout from "./Logout";
import Search from "./Search";
import Services from "./Services";
import {Link, useLocation, useParams} from "react-router-dom";
import CalendarAndPlaces from "../../containers/CalendarAndPlaces";
import Calendar from "./Calendar";

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  search: {
    marginLeft: 'auto',
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  logout: {
    marginLeft: '0',
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  services: {
    marginLeft: '0',
    padding: `0 ${theme.spacing.unit * 2}px`,
  },
  tabPlace: {
    minWidth: theme.spacing.unit * 10,
  },
});

const PlaceTab = ({ className, path, name }) => (
  <Tab
    key={path}
    label={name}
    classes={{ root: className }}
    component={Link}
    value={path}
    to={path}
  />
);

const BareTabs = ({ classes, places }) => {
  const params = useParams();
  const tabs = React.useMemo(() => [
    {
      name: 'All',
      id: undefined,
    },
    ...places,
  ].map(({ name, id }) => (    {
    name,
    path: CalendarAndPlaces.PATH.generate({ ...params, placeId: id }),
  })), [params, places])

  const location = useLocation();
  const selected = location.pathname === "/"
    ? tabs[0]
    : tabs.find(({ path }) => path === location.pathname);
  const value = selected ? tabs.indexOf(selected) : null;

  const element = value !== null ? (<Tabs value={value}>
    {tabs.map(({ name, path }) => <PlaceTab key={path} name={name} path={path} className={classes.tabPlace}/>)}
  </Tabs>) : <Calendar />;

  return (
    <div className={classes.root}>
      {element}
      <Search className={classes.search}/>
      <Services className={classes.services}/>
      <Logout className={classes.logout}/>
    </div>
  );
};

BareTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  places: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default withStyles(styles)(BareTabs);
