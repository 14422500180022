import * as React from 'react';
import styled from '@emotion/styled/macro';
import {Form as FormikForm, Formik} from 'formik';
import { useNavigate, useParams} from 'react-router-dom';
import Button from "@material-ui/core/Button/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-GB";
import {gql} from "graphql.macro";
import {Mutation} from "react-apollo";
import omit from "lodash/omit";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Input from "../Form/Input";
import AsyncSelect from "../Form/AsyncSelect";
import Select from "../Form/Select";
import Date from "../Form/Date";
import FormError from "../Form/FormError";
import CalendarPath from "../../containers/CalendarAndPlaces.path";

import schema from "./schema";
import MESSAGES from "./messages";
import SAVE_MUTATION from './SaveRecord.mutation';
import Attachments from "./Attachments";

const statuses = [
  [0, 'New'],
  [10, 'Pending'],
  [20, 'Resolved'],
  [30, 'Cancelled'],
].map(([value, title]) => ({ value, title }));

const PLACES_QUERY = gql`
  {
    viewer {
      places {
        id
        title:name
      }
    }
  }
`;

const ErrorDialog = ({ error, resetError }) => {
  if (error === null) return null;
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Something went wrong</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {error.message}
        </DialogContentText>
        <pre>
            {JSON.stringify(error)}
          </pre>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetError} color="primary">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function EditorForm({ className, record, userRole }) {
  const onError = React.useCallback(() => {}, []);
  const navigate = useNavigate();
  const params = useParams();
  const [error, setError] = React.useState(null);

  const resetError = React.useCallback(() => setError(null), []);

  const goToList = ({saved: {record: {id, date, place: {id: placeId}}}}) => {
    const isNew = record.id !== id;
    if (isNew) {
      // FIXME: Проверить, что тут правильный редирект
      // back button will return to edit page instead of create
      navigate(CalendarPath.generate({...params, id}), { replace: true });
    }

    navigate(CalendarPath.generate({placeId, from: date}));
  };

  const onSubmit = (mutate, initial) => async (values, {setSubmitting}) => {
    const confirmationRequired = (values.id && ['date', 'time', 'startPoi'].reduce(
        (acc, k) => initial[k] === values[k] ? acc : acc + 1, 0)
    ) >= 2;
    const save = (copy) => {
      const record = omit(schema.cast(values), ['attachments', 'new_attachments']);
      if (copy) {
        record.id = null;
      }

      mutate({variables: {record: {...record, new_attachments: values.new_attachments}}})
        .then(() => setSubmitting(false), (err) => {
          setSubmitting(false);
          setError(err);
        });
    };

    if (confirmationRequired) {
      confirmAlert({
        title: 'This record will be overwritten.',
        message: 'Are you sure to do this?',
        buttons: [
          {
            label: 'Yes, save it!',
            onClick: () => save(false),
          },
          {
            label: 'No, make a copy',
            onClick: () => save(true),
          },
          {
            label: 'Cancel',
            onClick: () => setSubmitting(false),
          },
        ]
      })
    } else {
      save(false);
    }
  };

  const renderError = ({loading, error}) => {
    if (!error || loading) return null;
    const [{message}] = error.graphQLErrors;
    return <FormError>{MESSAGES[message] || 'Unknown error'}</FormError>;
  };

  const saveAsNew = formik => () => {
    formik.setFieldValue('id', null, false);
    formik.submitForm();
  };

  const renderForm = (formik) => {
    const {isSubmitting} = formik;
    const { id } = record;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <FormikForm className={className}>
          <AsyncSelect label="Place" name="countryId" className="place" query={PLACES_QUERY} path="viewer.places"/>
          <Date label="Date" name="date" className="date"/>
          <Input label="Time" name="time" className="time" helperText="hh:mm or hh:mm-hh:mm"/>
          <Input label="PAX" name="pax" className="pax" type="number"/>
          {(userRole !== 'driver' && userRole !== 'guide' ? <Input label="Expected price" name="expectedPrice" className="expectedPrice" type="number"/> : null)}
          <Select label="Status" name="status" className="status" options={statuses}/>
          <Input label="Excursion" name="excursion" className="excursion" multiline/>
          <Input label="Ref" name="ref" className="ref" multiline/>
          <Input label="Begin on" name="startPoi" className="begin-on"/>
          <Input label="Note" name="note" className="note" multiline/>
          <Input label="Executor" name="implementer" className="executor" multiline/>
          <Attachments name="attachments" className="attach"/>

          <div className="buttons">
            {id ? <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={saveAsNew(formik)}
            >
              Save as new
            </Button> : null}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </div>
        </FormikForm>
      </MuiPickersUtilsProvider>
    );
  };

  const renderFormik = (mutate, result) => {
    const initial = schema.cast(record);
    return (
      <>
        {renderError(result)}
        <Formik
          initialValues={initial}
          onSubmit={onSubmit(mutate, initial)}
          validationSchema={schema}
        >
          {renderForm}
        </Formik>
      </>
    )
  };

  return (
    <>
      <ErrorDialog error={error} resetError={resetError} />
      <Mutation mutation={SAVE_MUTATION} onCompleted={goToList} onError={onError}>
        {renderFormik}
      </Mutation>
    </>
  );
}

export default styled(EditorForm)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: flex-start;
  margin-bottom: 0!important;

  & > .buttons {
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 16px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0 -5px 5px -3px #fafafa;
    margin-top: 8px;

    & > button {
      margin-left: 16px;
    }

    @media print {
      display: none;
    }
  }

  & > .place, & > .date, & > .time, & > .status, & > .pax, & > .expectedPrice {
    width: calc((100% / 3) - 0.5em);
  }

  @media(max-width: 1000px) {
    & > .place, & > .date, & > .time, & > .status {
      width: 100%;
    }
  }
`;
