import * as React from 'react';
import { Global, css } from '@emotion/core'

const GlobalStyles = () => (
  <Global
    styles={css`
        html, body, #root {
          display: flex;
          flex: 1;
          min-height: 100%;
        }
      `}
  />
);

export default GlobalStyles;
