import styled from '@emotion/styled/macro';

export default styled.div`
  position: relative;
  transition: filter 0.5s ease-in;
  
  &.blur {
    filter: blur(100px);
  }
`;
