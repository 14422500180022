import * as React from 'react';
import styled from '@emotion/styled/macro';
import Button from '@material-ui/core/Button';
import {Link, useParams} from "react-router-dom";
import {endOfISOWeek, format, startOfDay, startOfISOWeek} from "date-fns";

import CalendarPath from "../../containers/CalendarAndPlaces.path";

const toISO = d => format(d, 'yyyy-MM-dd');

const BareShortcuts = ({className, match, place}) => {
  const params = useParams();
  const today = startOfDay(new Date());
  const todayUrl = {
    ...params,
    place,
    from: toISO(today),
    to: null,
  };
  const thisWeekUrl = {
    ...params,
    place,
    from: toISO(startOfISOWeek(today)),
    to: toISO(endOfISOWeek(today)),
  };

  return (
    <div className={className}>
      <Button component={Link} to={CalendarPath.generate(todayUrl)}>Today</Button>
      <Button component={Link} to={CalendarPath.generate(thisWeekUrl)}>This week</Button>
    </div>
  )
};

export default styled(BareShortcuts)`
  display: flex;
  margin: 0 0.5em;
  & > a {
    margin: 0 0.25em;
  }
`;
