import { gql } from "graphql.macro";
import dayFragment from "../Calendar/Day.fragment";
import recordFragment from "./Record.fragment";

export default gql`
  fragment FullDay on Day {
    ...DayStat
    records(statuses: $statuses) {
      ...Record
    }
  }

  ${dayFragment}
  ${recordFragment}
`;
