import * as React from 'react';
import styled from '@emotion/styled/macro';
import UnStyledAppBar from '@material-ui/core/AppBar/AppBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useToggle } from 'react-use';

import Logout from '../AppBar/Logout';

import BackToList from './BackToList';
import Form from './Form';
import History from './History';

const PDFViewer = React.lazy(() => import('../PDF'));

const AppBar = styled(UnStyledAppBar)`
  && {
    display: flex;
    flex-direction: row;
  }

  @media print {
    && {
      display: none;
    }
  }
`;

const PDFSwitch = styled(FormControlLabel)`
  && {
    margin-left: 16px;
    padding-right: 16px;
    border-left: 1px solid #3B4D9F;
    border-right: 1px solid #3B4D9F;
  }

  & > span:last-child {
    color: #fff;
  }
`;

const Editor = styled(({ record, className, userRole }) => {
  const [on, toggle] = useToggle(false);

  const filteredHistory = React.useMemo(() => {
    return record.history?.filter(({ field }) => field !== 'expected_price' || (userRole !== 'driver' && userRole !== 'guide')) ?? [];
  }, [record.history])

  return (
    <div className={className}>
      <AppBar position="static">
        <BackToList />
        <PDFSwitch
          control={
            <Switch
              checked={on}
              onChange={() => toggle()}
            />
          }
          label="PDF"
        />
        <Logout />
      </AppBar>

      {on ? (
        <React.Suspense fallback={<div/>}>
          <PDFViewer data={record} userRole={userRole} />
        </React.Suspense>
      ) : (
        <div>
          <History owner={record.manager} created={record.insertedAt} updated={record.updatedAt} entries={filteredHistory} userRole={userRole} />
          <Form record={record} userRole={userRole} />
        </div>
      )}
    </div>
  );
})`
  ${AppBar} {
    & > button:last-child {
      margin-left: auto;
    }
  }

  ${AppBar} + div {
    display: flex;
    flex-direction: row-reverse;

    & > ${History} {
      margin: 1em;
      flex: 1;
      max-width: 500px;
      width: 34%;
    }

    & > ${Form} {
      margin: 1em;
      flex: 2;
      width: 66%;
    }
  }
`;

export default Editor;
