import { gql } from "graphql.macro";

import dayFragment from "../Calendar/Day.fragment";

import fullRecordFragment from "./FullRecord.fragment";

export default gql`
  mutation SaveRecord($record: MutatedRecord!) {
    saved:saveRecord(record: $record) {
      record {
        ...FullRecord
      }
      days {
        ...DayStat
      }
    }
  }

  ${fullRecordFragment}
  ${dayFragment}
`;
